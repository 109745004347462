
.Login__header {
    text-align: center;
    margin-top: 2em;
}
.Login__container {
    width: 500px;
    margin: auto;
    margin-top: 3em;
    margin-bottom: 5em;
    background-color: #f9f9f9;
    border: 1px solid f9f9f9;
    padding: 3em;
}

.Login__wrapper {
    height: 75vh;
}

.Login input {
    height: 3.2em;
}

.Login button {
    height: 3.2em;
}

.Login__forgot_password {
    float: right;
}

.Login__item {
    margin-bottom: 1.5em;
}

.Login .error {
    color: #ff4d4f;
    margin-bottom: 1em;
    margin-top: 0;
}