.App__logo {
  width: 220px;
  height: 60px;
  float: left;
  background-repeat: no-repeat;
  background-size: auto;
  margin-right: 50px;
}
.App__logo h1 {
  float: right;
}
.App__logo img {
  width: 55px;
  height: 55px;
}

.mt-1 {
  margin-top: 1em;
}

.mt-2 {
  margin-top: 2em;
}

.mt-3 {
  margin-top: 3em;
}

.ml-5 {
  margin-left: 5em;
}

.mr-5 {
  margin-right: 5em;
}

.mt-5 {
  margin-top: 5em;
}

.mb-5 {
  margin-bottom: 5em;
}

.mt-4 {
  margin-top: 4em;
}

.mb-4 {
  margin-bottom: 4em;
}

.m-4 {
  margin: 4em;
}

.m-3 {
  margin: 3em;
}

.m-2 {
  margin: 2em;
}
.m-1 {
  margin: 1em;
}