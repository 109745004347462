.Projects {
    min-height: 75vh;
}
.Projects__headerbar {
    margin-bottom: 1em;
    float: right;
}

.Projects__header_button {}

.Projects__new {
    width: 50%;
    margin: auto;
    height: 330px;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
    background-color: white;
    padding: 2em;
    text-align: center;
}

.Project__new_img {
    background: url('../../img/create-project.png') no-repeat;
    background-size: cover;
    margin: auto;
    width: 150px;
    height: 150px;

}