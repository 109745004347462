.URLDetail {
    min-height: 75vh;
    padding: 2em;
}

.URLDetail__status {
    padding: 4px 5px;
    font-size: 12px;
    margin-left: 10px;
    background-color: rgb(150, 202, 150);
}


.URLDetail__status_yellow {
    background-color: #eeedb3;
}

.URLDetail__draft, .URLDetail__draft .ant-table-column-sort {
    background-color: #eeedb3;
}

.URLDetail h2 {
    font-size: 15px;
}

.URLDetail h2 u {
    font-size: 13px;
}


.ant-dropdown-menu-item-selected {
    color: #000;
    background-color: #C0C0C0 !important;
}

.selectButtons {
    clear: both;
    margin: 0.5rem;
}