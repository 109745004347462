.ProjectDetail {
    min-height: 75vh;
    padding: 2em;
}


.ProjectDetail h1 a {
    font-size: 15px;
}


.ProjectDetail h1 input {
    width: 300px;
}