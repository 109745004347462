.ProjectPermission {
    min-height: 75vh;
    padding: 2em;
}

.ProjectPermission__container {
    width: 500px;
    margin: auto;
    margin-top: 3em;
    margin-bottom: 5em;
    background-color: #fbfbfb;
    border: 1px solid #f9f9f9;
    padding: 3em;
    padding-top: 1em;
}

.ProjectPermission__container_back {
    margin-left: -3em;
    margin-bottom: 1.5em;
}

.ProjectPermission__wrapper {
    min-height: 75vh;
}