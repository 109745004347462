.AddProject__container {
    width: 500px;
    margin: auto;
    margin-top: 3em;
    margin-bottom: 5em;
    background-color: #fbfbfb;
    border: 1px solid #f9f9f9;
    padding: 3em;
    padding-top: 1em;
}

.AddProject__wrapper {
    height: 75vh;
}

.AddProject {
    min-height: 75vh;
}

.AddProject .error {
    color: #ff4d4f;
    margin-bottom: 1em;
    margin-top: 0;
}