.AddTranslation {
    display: inline;
    float:left;
    margin-left:10px;
    width: 40%;
}

.AddTranslation_wrapper {
    clear: both;
    margin-left: 10px;
}

.ant-form-vertical .ant-form-item-label>label,
.ant-col-24.ant-form-item-label>label,
.ant-col-xl-24.ant-form-item-label>label {
    display: none;
    margin: 0;
    visibility: hidden
}