@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less'; // Import Ant Design styles by less entry


@primary-color: #000; // primary color for all components
@link-color: #1890ff; // link color
@success-color: #52c41a; // success state color
@warning-color: #faad14; // warning state color
@error-color: #f5222d; // error state color
@font-size-base: 14px; // major text font size
@heading-color: rgba(0, 0, 0, 0.85); // heading text color
@text-color: rgba(0, 0, 0, 0.65); // major text color
@text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
@disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-radius-base: 2px; // major border radius
@border-color-base: #d9d9d9; // major border color
@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
  0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers
//@layout-header-background: #eeedb3;
@layout-header-background: #fff;
@layout-header-height: 80px;
@table-selected-row-bg: rgba(0, 0, 0, 0.05);

@font-face {
  font-family: 'Proxima Nova';
  src: url('./fonts/Proxima Nova Font.otf') format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura';
  src: local('Gilda Display'), url('./fonts/futura/futura medium bt.ttf') format('woff');
}


body {
    margin: 0;
    font-family: 'Proxima Nova', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: rgba(0, 0, 0, 0.2);
  }
  h1,h2,h3,h4,h5,h6 {
    font-family: 'Futura';
  }

  .ant-menu {
    //background-color: #eeedb3;
  }

  button.link {
    background: none;
    border: none;
    color:#1890ff;
    cursor: pointer;
  }